<template>
    <div id="root">
        <div class="container">
          <h2 class="text-center recipe-title">Cream Cheese for Shavuot :))</h2>
        <div class="recipe-container">
          <div v-for="(recipe, index) in recipes" :key="index" class="col-md-4 col-sm-4 col-lg-4 recipe">
            <h4>{{ recipe.name }}</h4>
            <br/>
            <ul>
              <li v-for="(ingredient, index) in recipe.ingredients" :key="index"> 
                {{ ingredient.food }}, {{ ingredient.quantity }} 
              </li>
            </ul>
            <div class="cooking-instructions">
              <h3 class="text-center">Instructions</h3>
              <ol>
                <li v-for="(detail, index) in recipe.instructions" :key="index">{{ detail }}</li>
              </ol>
            </div>
          </div>
        </div>
        <h2 class="text-center recipe-title">Bon Appétit</h2>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RecipeBook',
    data() {
      return {
        recipes: [
          {
            name: 'No-Bake Mascarpone and Cream Cheese Crumble Cheesecake',
            ingredients: [
              { food: 'tea biscuits (Petite Beurre)', quantity: '1 package' },
              { food: 'butter', quantity: '200 grams' },
              { food: 'heavy cream', quantity: '2 containers' },
              { food: 'sour cream', quantity: '1 container' },
              { food: 'instant vanilla pudding mix', quantity: '1 package' },
              { food: 'mascarpone cheese', quantity: '1 container' },
              { food: 'cream cheese', quantity: '3 tablespoons' },
              { food: 'plain or vanilla yogurt', quantity: '1 container' },
              { food: 'sugar', quantity: '1/2 cup' }
            ],
            instructions: [
              'Crush the tea biscuits into crumbs.',
              'Melt the butter in the microwave.',
              'Arrange the crumbs in the bottom of a pan and pour the melted butter over them, pressing firmly.',
              'Bake in the oven at 200°C (390°F) for 15 minutes, until golden brown and firm.',
              'In a mixer, use a whisk attachment to combine all the ingredients: heavy cream, sour cream, instant vanilla pudding mix, mascarpone cheese, cream cheese, yogurt, and sugar.',
              'Mix on medium speed for about 5 minutes, until the mixture is smooth and stable.',
              'Pour the cheese mixture over the cooled crust.',
              'Sprinkle additional crumbs over the cheese mixture. Alternatively, you can spread chocolate, Lotus spread, or fruit on top.',
              'Refrigerate the cheesecake for several hours or until set.',
              'Slice and serve chilled. Enjoy your delicious Cheesecake Crumble!'
            ]
          }
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  #root {
    background-color: #FAF7F2;
    background-image: url('../assets/test.jpg');
  }
  
  .recipe-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .recipe-title {
    margin-top: 15px;
    font-family: 'Lobster', cursive;
    text-align: center;
  }
  
  .recipe {
    background: #FAF7F2;
    padding: 15px;
    margin: 15px;
    box-shadow: 1px 3px 11px 0px rgba(0, 0, 0, 0.75);
  }
  
  .cooking-instructions {
    padding: 5px;
  }
  
  p {
    margin-left: 20px;
  }
  
  h4 {
    margin-left: 20px;
    font-family: 'Lobster', cursive;
  }
  </style>
  