<template>
  <div class="eve-slider-section">
    <div class="eve-profile-info">
      <div class="d-flex align-items-center w-100">
        <div class="mx-auto eve-profile-inner">
          <!-- <h2>EVE COHEN<span>Makeup Artist</span></h2> -->
        </div>
      </div>
    </div>
    <div @click="scrollDown" class="arrow bounce">
      <a class="smoothScroll"><i class="fa fa-angle-down fa-5x" aria-hidden="true"></i></a>
    </div>

    <video-background
          @ended="showRecipe"
          @ready="startVideo"
          src="https://res.cloudinary.com/evecohen/video/upload/v1717999349/cake-high-res_pgmnkh.mp4"
          style="height: 100vh;"
          objectFit="cover"
          poster="https://res.cloudinary.com/evecohen/image/upload/v1717999910/cakev3_ztgjoe.jpg"
          ref="videobackground"
          playsWhen="canplaythrough"
          :muted="true"
      >
      </video-background>
    
    <div v-if="showRecipeText" class="recipe">
      <h1>Cake Recipe</h1>
      <p>Here is the recipe for the cake...</p>
    </div>
    <Recipe />
  </div>
</template>

<script>
import { useWindowSize } from 'vue-window-size';
import Recipe from './components/Recipe.vue';

export default {
  name: 'CaruselSection',
  components: {
    Recipe
  },
  setup() {
    const { width } = useWindowSize();
    const isMobileScreen = width.value <= 600;
    return {
      isMobileScreen
    };
  },
  data() {
    return {
      showVideo: true,
      showRecipeText: false,
      muted: true
    };
  },
  methods: {
    async startVideo() {
      try {
        const video = document.getElementsByTagName("video");
        if (video) {
          video[0].setAttribute("muted", "muted");
          video[0].setAttribute("playsinline", "playsinline");
          video[0].removeAttribute("controls");
          await video[0].play();
        }
      } catch (e) {
        console.log(e);
        this.$sentry.captureException(e);
      }
    },
    scrollDown() {
      window.scrollTo({
        behavior: 'smooth',
        top: 800,
      });
    },
    showRecipe() {
      this.showVideo = false;
      this.showRecipeText = true;
    }
  }
};
</script>

<style>
 body {
  margin: 0px !important;
  background-color: #FAF7F2;
  background-image: url('./assets/test.jpg');
 }
@import url('//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css');
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.arrow {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  z-index: 999;
  height: 80px;
  margin-bottom: 30px;
}
.bounce {
  animation: bounce 2s infinite;
}
i {
  display: block;
  color: #fff;
}

@media all and (max-width: 767px) {
  .arrow {
    height: 120px;
  }
}
.video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.recipe {
  text-align: center;
  padding: 20px;
  text-align: left;
}
.arrow {
  width: 0; 
  height: 0; 
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid white;
  
}

.test {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #98ddba;
  height: 100vh;
}
.recipe-title {
  margin-left: 20px;
}

</style>
